import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { faVuejs } from '@fortawesome/free-brands-svg-icons'
import {dom} from '@fortawesome/fontawesome-svg-core'
// import BootstrapVue3 from "bootstrap-vue-3";
//
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
library.add(faVuejs);
library.add(fas);
library.add(far)
dom.watch()
import './style/global.css';

createApp(App).component('font-awesome-icon', FontAwesomeIcon)

createApp(App).use(store).use(router).mount('#app')
