<template>
  <div class="home">
    <h2 class="text-2xl mb-2">Bienvenue sur cet annuaire</h2><br>
    <div>
      <p class="texteInfo">
        Celui-ci regroupe en fait 2 annuaires différents:
      </p>
    </div>
    <div class="elements">
      <div style="justify-self: center; align-self: center">
        <img src="@/assets/repedo.png" style="width:180px" alt="image repedo">
      </div>
      <div style="align-self: center" class="col-span-4 texteRepedo">
        <p class="parties">1) Annuaire des organismes intervenant en pédologie : <strong>Repedo</strong>.</p>
        <p>Vous recherchez des informations concernant des organismes intervenant en pédologie,
          en science du sol ou encore dans le domaine de la pollution des sols ?<br>
          Naviguez dans le répertoire national des organismes intervenant en pédologie <strong>Repedo</strong>.
          <br><br>
          Faites votre recherche à partir de mot-clé, de critère géographique, de type d’organisme, etc.
          <br>
          Vous y trouverez les coordonnées des organismes concernés.
        </p>
      </div>
    </div>
    <div class="elements">
      <div style="justify-self: center; align-self: center"><img src="@/assets/applicasol.png" style="width:180px" alt="image applicasol"></div>
      <div class="col-span-4 texteApplicasol">
        <p class="parties">2) Liste des applications thématiques : <strong>Applicasol</strong>.</p>
        <p>Applicasol est un système d’information  référençant et facilitant la mise en commun de l’ensemble des applications
          thématiques réalisées
          <br>à partir des bases de données cartographiques sur les sols, ainsi que les méthodes de traitement utilisées.
          <br><br>
          Des données cartographiques sur les sols sont aujourd’hui disponibles au format national DoneSol. <br>
          De nombreuses applications, en réponse à des enjeux et des demandes exprimés localement, sont développées à partir de ces inventaires et pourraient bénéficier à d’autres acteurs.
          <br>Les données géographiques sur les sols, combinées à d’autres informations (climat, relief, occupation du sol, pratiques agricoles, hydrologie, hydrogéologie, etc) offrent une gamme d’applications thématiques très étendue :
          gestion et protection des sols, gestion du territoire, aménagement, zonages, préservation de la biodiversité, etc.<br><br>
          La complexité des méthodes de traitement mises en œuvre est variable, allant de la simple extraction et traitement de données sols, en passant par l’élaboration de règles de pédotransfert,<br> jusqu’à la mise en place de modèles plus ou moins complexes intégrant des variables « non-sol ».
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default ({
  name: 'Home',
});
</script>

<style>
.home{
  @apply mt-5;
  min-height: 625px;
}
.texteInfo{
  @apply mb-4 underline
}
.parties{
  @apply underline
}
.texteRepedo{
  @apply text-left
}
.texteApplicasol{
  @apply text-left
}
.elements{
  @apply grid grid-cols-5 gap-4 mb-4 mt-4
}
</style>
