<template>
  <div id="app">
    <div class="card">
      <nav id="header" class="flex items-center justify-between flex-wrap p-2">
        <div class="ml-3">
          <router-link :to="{ name: 'Home'}">
            <i class="fa-solid fa-house fa-2xl"></i>
          </router-link>
        </div>
        <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
          <div class=" text-3xl lg:flex-grow" >
            <a>
              <router-link :to="{ name: 'Home'}"><strong>Annuaires</strong> Repedo / Applicasol </router-link>
            </a>
          </div>
        </div>
      </nav>
      <div class="bntRoutage">
        <router-link :to="{ name: 'repedo'}">
          <button class="btn-home">
            Organisme
          </button>
        </router-link>
        <router-link :to="{ name: 'applicasol'}">
          <button class="btn-home">
            Application Thématique
          </button>
        </router-link>
      </div>
      <router-view/>
      <div >
        <div class="logo grid grid-flow-col auto-cols-max justify-center">
          <div><img src="@/assets/logo-GIS.png" style="width:100px;margin-right: 30px" alt="logo du 1"></div>
          <div><img src="@/assets/Logo-INRAE.png" style="width:100px" alt="logo 2"></div>
        </div>
        <div>
          Copyright © 2009-{{ new Date().getFullYear() }} INRAE,  Tous droits réservés
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app',
};
</script>

<style>
body {
  
  text-align: center !important;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #f6f7f8;
  flex: 1;
  font-size: 14px;
}

.card {  
  background-color: #f6f7f8;
}

#header {
  color: white;
  text-align: center;
  background-color: #344D59;
}

.bntRoutage {
  text-align: center;
  padding-top: 2em;
  padding-bottom: 1em;
  
}

.logo {
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: center;
  background-color: #f6f7f8;
}

#footer {
  color: white;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 1rem;
  background-color: #2c3e50;
  position: sticky;
  bottom: 0;
}

.btn-home {
  @apply font-bold py-2 px-4 rounded-full bg-blue-900 text-white;
  margin: 1em;  
}

.btn-home:hover {
  @apply bg-blue-400;
}
</style>
