import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import {RouteMeta} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/repedo',
    name: 'repedo',
    component: () => import('../views/ListOrganismes.vue'),
  },
  {
    path: '/applicasol',
    name: 'applicasol',
    component: () => import('../views/ListApplications.vue'),
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {  

  // Vérifie si la route demandée existe
  const routeExists = router.getRoutes().some(route => route.path === to.path)
  if (!routeExists) {
    // La route n'existe pas, redirige vers "/"
    next('/')
    return
  }
  // Sinon, accès à la route demandée
  next()
})

export default router
